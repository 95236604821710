@use '~/src/lib/mixins';

.IndexPartners {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	margin-top: var(--size-gap);
	background-color: var(--color-white);
	padding: var(--size-gap-2x) 0;

	.ContentContainer > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: var(--size-gap);
	}

	.BrandsContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		vertical-align: middle;
		gap: var(--size-gap-2x);
		padding: 0 64px;
		width: 100%;

		@include mixins.desktop {
			align-items: center;
			gap: var(--size-gap-2x);
		}

		.Brands {
			display: flex;
			flex-basis: 400px;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			align-items: center;
			gap: 40px 0;
			width: 100%;

			@include mixins.tablet {
				flex-basis: 700px;
			}

			@include mixins.wide-desktop {
				flex-basis: unset;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 145px;
				height: 42px;
				text-align: center;

				&.Invisalign {
					img {
						width: 130px;
					}
				}

				&.TheRealCost {
					top: -5px;
					left: 5px;
				}
			}
		}
	}
}
