@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexGames {
	.TabsContainer {
		display: flex;
		justify-content: center;
		background-color: var(--color-white);
	}

	.Games {
		display: flex;
		align-items: stretch;
		gap: var(--size-gap-half);
		background-color: transparent;
		padding: var(--size-gap-half);
		padding-right: 0;
		width: var(--app-width);
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-snap-type: x proximity;

		> :first-child {
			margin-left: auto;
		}
		> :last-child {
			margin-right: auto;
		}

		@include mixins.desktop {
			padding: var(--size-gap);
		}

		.MoreGamesContainer {
			display: flex;
			justify-content: stretch;
			// Need this hack to avoid scroll snap weirdness
			padding-right: var(--size-gap);
			scroll-snap-align: end;

			.MoreGames {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: var(--size-gap-quarter);
				border-radius: 16px;
				background-color: var(--color-white);
				padding: var(--size-gap) var(--size-gap);
				color: var(--color-brand-primary);
				font-size: var(--font-small);
				text-align: center;
				white-space: nowrap;
			}
		}
	}
}
