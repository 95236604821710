@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexLeaders {
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 1fr;
	border-radius: 16px;
	background-color: var(--color-white);
	padding: var(--size-gap);

	@include mixins.desktop {
		margin: 0;
		padding: var(--size-gap) var(--size-gap-2x);
	}

	.Season {
		margin: var(--size-gap-half) var(--size-gap-quarter) var(--size-gap);
		font-family: var(--font-highlight);
	}

	.MoreContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: var(--size-gap);
		padding: var(--size-gap);
		padding-bottom: 0;
		a {
			width: 100%;
			max-width: 360px;
		}
	}
}
