.IndexEvents {
  background: var(--color-light-gray);
  padding: var(--size-gap-half) 0;
}

.IndexEvents .Heading {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IndexEvents .Heading h2 {
  color: var(--color-white);
  font: var(--font-headline-m);
}

.IndexEvents .Heading .More {
  color: var(--color-brand-primary);
  font: var(--font-body-s-r);
  padding: 0 16px 0 0;
  display: block;
}

@media (width >= 1024px) {
  .IndexEvents .Heading .More {
    padding: 0;
  }
}

.IndexEvents .Heading .More svg {
  margin-left: var(--size-gap-half);
  color: var(--color-brand-primary);
}

@media (width >= 1024px) {
  .IndexEvents.hide {
    padding: 0;
  }

  .IndexEvents {
    padding: 32px 0;
  }
}

.IndexEvents h1 {
  margin: 0 0 0 var(--size-gap);
}

@media (width >= 1024px) {
  .IndexEvents h1 {
    margin: 0;
  }
}

.IndexEvents .MoreContainer {
  padding: var(--size-gap);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.IndexEvents .MoreContainer a {
  width: 100%;
  max-width: 360px;
}

.IndexEvents .NoEvents {
  padding: var(--size-gap);
}

.IndexEvents ul {
  gap: var(--size-gap);
  padding: var(--size-gap);
  max-width: var(--app-width);
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  flex-direction: row;
  margin-bottom: 0;
  padding-top: 0;
  display: flex;
  overflow-x: auto;
}

@media (width >= 1024px) {
  .IndexEvents ul {
    padding: 0;
  }
}

.IndexEvents ul li {
  background-color: var(--color-white);
  padding: var(--size-gap-half);
  scroll-snap-align: center;
  border-radius: 16px;
  grid-template-columns: 218px;
  line-height: 1.4;
  display: grid;
}

.IndexEvents ul li .Information {
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  padding: 0 8px;
  display: grid;
}

.IndexEvents ul li .Information .Eyebrow {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  font-family: var(--font-highlight);
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  background: #46928f;
  border-radius: 16px;
  width: fit-content;
  padding: 4px 16px;
}

.IndexEvents ul li .Information .Content {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.IndexEvents ul li a {
  color: var(--color-black);
  grid-template-rows: auto 1fr;
  gap: 16px;
  width: 100%;
  display: grid;
}

.IndexEvents ul li a:hover {
  text-decoration: none;
}

.IndexEvents ul li img {
  aspect-ratio: 16 / 9;
  border-radius: 10px;
}

.IndexEvents ul li .SoldOutOverlay {
  z-index: 1;
  background-color: #ffffffa6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.IndexEvents ul li .SoldOutOverlay > div {
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: 10px;
  line-height: 1;
  font-family: var(--font-highlight);
  text-align: center;
  text-transform: uppercase;
  padding: 2px 8px;
}

.IndexEvents ul li .MatchUps {
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  gap: 2px;
  display: grid;
  overflow: hidden;
}

.IndexEvents ul li .MatchUps .MatchUp {
  background-color: #d3d3d3;
  grid-template-columns: 1fr;
  display: grid;
}

.IndexEvents ul li .MatchUps .MatchUp .Teams {
  grid-template-columns: 1fr 1fr;
  grid-row-start: 1;
  grid-column-start: 1;
  display: grid;
}

.IndexEvents ul li .MatchUps .MatchUp .Teams .Team {
  display: grid;
}

.IndexEvents ul li .MatchUps .MatchUp .Teams .Team img {
  object-fit: contain;
  place-self: center;
  width: 40px;
  height: 40px;
}

.IndexEvents ul li .MatchUps .MatchUp .Versus {
  background-color: #000;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: 10px;
  line-height: 1;
  font-family: var(--font-highlight);
  text-transform: uppercase;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  padding: 2px 8px;
}

.IndexEvents ul li .Title {
  padding: 0 var(--size-gap-quarter);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.1;
  font-family: var(--font-highlight);
  white-space: pre-line;
  width: 100%;
  max-width: 100%;
}

.IndexEvents ul li .Date {
  padding: 0 var(--size-gap-quarter);
}

.IndexEvents ul li .Tickets {
  padding: 0 var(--size-gap-quarter);
  color: var(--color-brand-primary);
}

.IndexEvents ul li div, .IndexEvents ul li .Tickets {
  font-size: var(--font-small);
}

.IndexEvents ul li div > svg, .IndexEvents ul li .Tickets > svg {
  padding-left: var(--size-gap-quarter);
}

.IndexEvents ul li div.SoldOut, .IndexEvents ul li .Tickets.SoldOut {
  color: var(--color-gray);
  text-decoration: line-through;
}

.IndexGames .TabsContainer {
  background-color: var(--color-white);
  justify-content: center;
  display: flex;
}

.IndexGames .Games {
  align-items: stretch;
  gap: var(--size-gap-half);
  padding: var(--size-gap-half);
  width: var(--app-width);
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  background-color: #0000;
  padding-right: 0;
  display: flex;
  overflow-x: auto;
}

.IndexGames .Games > :first-child {
  margin-left: auto;
}

.IndexGames .Games > :last-child {
  margin-right: auto;
}

@media (width >= 1024px) {
  .IndexGames .Games {
    padding: var(--size-gap);
  }
}

.IndexGames .Games .MoreGamesContainer {
  padding-right: var(--size-gap);
  scroll-snap-align: end;
  justify-content: stretch;
  display: flex;
}

.IndexGames .Games .MoreGamesContainer .MoreGames {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-quarter);
  background-color: var(--color-white);
  padding: var(--size-gap) var(--size-gap);
  color: var(--color-brand-primary);
  font-size: var(--font-small);
  text-align: center;
  white-space: nowrap;
  border-radius: 16px;
  flex-direction: row;
  display: flex;
}

.IndexPartners {
  margin-top: var(--size-gap);
  background-color: var(--color-white);
  padding: var(--size-gap-2x) 0;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

.IndexPartners .ContentContainer > div {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap);
  flex-direction: column;
  display: flex;
}

.IndexPartners .BrandsContainer {
  vertical-align: middle;
  justify-content: space-evenly;
  gap: var(--size-gap-2x);
  flex-direction: row;
  width: 100%;
  padding: 0 64px;
  display: flex;
}

@media (width >= 1024px) {
  .IndexPartners .BrandsContainer {
    align-items: center;
    gap: var(--size-gap-2x);
  }
}

.IndexPartners .BrandsContainer .Brands {
  flex-flow: wrap;
  flex-basis: 400px;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px 0;
  width: 100%;
  display: flex;
}

@media (width >= 768px) {
  .IndexPartners .BrandsContainer .Brands {
    flex-basis: 700px;
  }
}

@media (width >= 1400px) {
  .IndexPartners .BrandsContainer .Brands {
    flex-basis: unset;
  }
}

.IndexPartners .BrandsContainer .Brands a {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 42px;
  display: flex;
}

.IndexPartners .BrandsContainer .Brands a.Invisalign img {
  width: 130px;
}

.IndexPartners .BrandsContainer .Brands a.TheRealCost {
  top: -5px;
  left: 5px;
}

:root {
  --color-brand-primary: #fc4506;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #d61b00;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-white: #fff;
  --color-gray: #979797;
  --color-darkgray: #1c1e21;
  --color-lightgray: #efefef;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-brand: alternate-gothic-no-3-d, sans-serif;
  --font-main: "roboto flex", helvetica neue, helvetica, arial, sans-serif;
  --font-highlight: vtf league, helvetica neue, helvetica, arial, sans-serif;
  --font-h1: 1.8rem;
  --font-h2: 1.4rem;
  --font-h3: 1.25rem;
  --font-small: .9rem;
  --font-tiny: .7rem;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: 990px;
}

.IndexPosts {
  background-color: var(--color-light-gray);
  padding: var(--size-gap);
}

@media (width >= 1024px) {
  .IndexPosts {
    padding: var(--size-gap-2x) 0;
  }
}

.IndexPosts .Heading {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IndexPosts .Heading .More {
  color: var(--color-brand-primary);
  font: var(--font-body-s-r);
  font-weight: 600;
  transition: all .5s;
  display: block;
}

.IndexPosts .Heading .More svg {
  margin-left: var(--size-gap-quarter);
  color: var(--color-brand-primary);
}

.IndexPosts .Posts {
  gap: var(--size-gap-half);
  padding-top: var(--size-gap-half);
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

@media (width >= 1024px) {
  .IndexPosts .Posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

.IndexPosts .Posts .Post {
  gap: var(--size-gap);
  border-radius: var(--size-gap);
  background-color: var(--color-white);
  padding: var(--size-gap-half);
  color: var(--color-black);
  grid-template-columns: 100px 1fr;
  display: grid;
}

.IndexPosts .Posts .Post:hover {
  text-decoration: none;
}

.IndexPosts .Posts .Post .PictureContainer {
  aspect-ratio: 4 / 3;
}

.IndexPosts .Posts .Post .PictureContainer .Picture img {
  border-radius: var(--size-gap-half);
  aspect-ratio: 4 / 3;
  object-fit: cover;
  width: 100%;
  display: block;
}

.IndexPosts .Posts .Post .TextContainer {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.IndexPosts .Posts .Post .TextContainer .Title {
  font-weight: 600;
  font-family: var(--font-main);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.IndexPosts .Posts .Post .TextContainer .PublishAt {
  color: var(--color-gray);
  font-size: var(--font-small);
}

.IndexDribl {
  z-index: 10;
  background-image: linear-gradient(to bottom right, #fff0 50%, #0006 100%);
  position: fixed;
  bottom: 0;
  right: 0;
}

.IndexDribl a {
  text-decoration: none;
}

.IndexLeaders {
  background-color: var(--color-white);
  padding: var(--size-gap);
  border-radius: 16px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 1024px) {
  .IndexLeaders {
    padding: var(--size-gap) var(--size-gap-2x);
    margin: 0;
  }
}

.IndexLeaders .Season {
  margin: var(--size-gap-half) var(--size-gap-quarter) var(--size-gap);
  font-family: var(--font-highlight);
}

.IndexLeaders .MoreContainer {
  margin-top: var(--size-gap);
  padding: var(--size-gap);
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  display: flex;
}

.IndexLeaders .MoreContainer a {
  width: 100%;
  max-width: 360px;
}

.Index {
  background-color: var(--color-lightgray);
  flex-direction: column;
  display: flex;
}

.Index .Cards {
  align-items: center;
  gap: var(--size-gap);
  background-color: var(--color-lightgray);
  grid-template-columns: 1fr;
  padding: 16px;
  display: grid;
}

@media (width >= 1024px) {
  .Index .Cards {
    justify-content: center;
    align-items: stretch;
    gap: var(--size-gap-2x);
    background-color: var(--color-lightgray);
    padding: var(--size-gap) 0;
    grid-template-columns: 1fr 1fr;
  }
}

.Index .Cards .PlayoffStandings .StandingsTable {
  grid-template-columns: auto repeat(2, 1fr);
}

/*# sourceMappingURL=Index.80529a12.css.map */
