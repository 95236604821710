@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexDribl {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.4) 100%);

	a {
		text-decoration: none;
	}
}
