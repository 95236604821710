@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexPartners {
	margin-top: var(--size-gap);
	background-color: var(--color-white);
	padding: var(--size-gap-2x) 0;

	.ContentContainer > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: var(--size-gap);
	}

	.BrandsContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		vertical-align: middle;
		gap: var(--size-gap-2x);

		@include mixins.desktop {
			flex-direction: column;
			align-items: center;
			gap: var(--size-gap-2x);
		}

		.Brands {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: var(--size-gap-2x);

			@include mixins.desktop {
				flex-direction: row;
				justify-content: space-evenly;
				gap: var(--size-gap-3x);
				padding: var(--size-gap-half) 0;
				width: 100%;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 145px;
				height: 42px;
				text-align: center;

				&.TheRealCost {
					top: -5px;
					left: 5px;
				}
			}
		}
	}
}
