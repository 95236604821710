@use '~/src/lib/_root';
@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexPosts {
	background-color: var(--color-light-gray);
	padding: var(--size-gap);

	@include mixins.desktop {
		padding: var(--size-gap-2x) 0;
	}

	.Heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.More {
			display: block;
			transition: all ease 0.5s;
			color: var(--color-brand-primary);
			font: var(--font-body-s-r);
			font-weight: 600;

			svg {
				margin-left: var(--size-gap-quarter);
				color: var(--color-brand-primary);
			}
		}
	}

	.Posts {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: var(--size-gap-half);
		padding-top: var(--size-gap-half);

		@include mixins.desktop {
			grid-template-columns: repeat(2, 1fr);
		}

		.Post {
			display: grid;
			grid-template-columns: 100px 1fr;
			gap: var(--size-gap);
			border-radius: var(--size-gap);
			background-color: var(--color-white);
			padding: var(--size-gap-half);
			color: var(--color-black);

			&:hover {
				text-decoration: none;
			}

			.PictureContainer {
				aspect-ratio: 4/3;

				.Picture {
					img {
						display: block;
						border-radius: var(--size-gap-half);
						aspect-ratio: 4/3;
						width: 100%;
						object-fit: cover;
					}
				}
			}

			.TextContainer {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.Title {
					font-weight: 600;
					font-family: var(--font-main);
					@include mixins.max-lines(2);
				}

				.PublishAt {
					color: var(--color-gray);
					font-size: var(--font-small);
				}
			}
		}
	}
}
