@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexEvents {
	background: var(--color-light-gray);
	padding: var(--size-gap-half) 0;

	.Heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		h2 {
			color: var(--color-white);
			font: var(--font-headline-m);
		}

		.More {
			display: block;
			padding: 0 16px 0 0;
			color: var(--color-brand-primary);
			font: var(--font-body-s-r);

			@include mixins.desktop {
				padding: 0;
			}

			svg {
				margin-left: var(--size-gap-half);
				color: var(--color-brand-primary);
			}
		}
	}

	&.hide {
		@include mixins.desktop {
			padding: 0;
		}
	}

	@include mixins.desktop {
		padding: 32px 0;
	}

	h1 {
		margin: 0 0 0 var(--size-gap);

		@include mixins.desktop {
			margin: 0;
		}
	}

	.MoreContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: var(--size-gap);
		a {
			width: 100%;
			max-width: 360px;
		}
	}
	.NoEvents {
		padding: var(--size-gap);
	}

	ul {
		display: flex;
		flex-direction: row;
		gap: var(--size-gap);
		margin-bottom: 0;
		padding: var(--size-gap);
		padding-top: 0;
		max-width: var(--app-width);
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;

		@include mixins.desktop {
			padding: 0;
		}

		li {
			display: flex;
			flex-shrink: 0;
			flex-direction: column;
			border-radius: 16px;
			background-color: var(--color-white);
			padding: var(--size-gap-half);
			width: 235px;
			scroll-snap-align: center;
			line-height: 1.4;

			.Information {
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 0 8px;

				.Eyebrow {
					border-radius: 16px;
					background: #46928f;
					padding: 4px 16px;
					width: fit-content;
					color: #fff;
					font-style: normal;
					font-weight: 800;
					font-size: 10px;
					line-height: 16px;
					font-family: var(--font-highlight);
					letter-spacing: 2px;
					text-align: center;
					text-transform: uppercase;
				}

				.Content {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}
			}

			a {
				display: flex;
				flex-direction: column;
				gap: 16px;
				color: var(--color-black);

				&:hover {
					text-decoration: none;
				}
			}

			img {
				border-radius: 10px;
				aspect-ratio: 16/9;
				width: 100%;
				object-fit: cover;
			}

			.SoldOutOverlay {
				display: flex;
				position: absolute;
				top: 0;
				left: 0;
				justify-content: center;
				align-items: center;
				z-index: 1;
				background-color: rgba(255, 255, 255, 0.65);
				width: 100%;
				height: 100%;

				> div {
					background-color: var(--color-black);
					padding: 2px 8px;
					color: var(--color-white);
					font-size: 10px;
					line-height: 1;
					font-family: var(--font-highlight);
					text-align: center;
					text-transform: uppercase;
				}
			}

			.MatchUps {
				display: grid;
				gap: 2px;
				border-radius: 8px;
				aspect-ratio: 16/9;
				overflow: hidden;

				.MatchUp {
					display: grid;
					grid-template-columns: 1fr;
					background-color: lightgray;

					.Teams {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-row-start: 1;
						grid-column-start: 1;

						.Team {
							display: grid;

							img {
								align-self: center;
								justify-self: center;
								width: 40px;
								height: 40px;
								object-fit: contain;
							}
						}
					}

					.Versus {
						grid-row-start: 1;
						grid-column-start: 1;
						align-self: center;
						justify-self: center;
						background-color: black;
						background-color: var(--color-black);
						padding: 2px 8px;
						color: var(--color-white);
						font-size: 10px;
						line-height: 1;
						font-family: var(--font-highlight);
						text-transform: uppercase;
					}
				}
			}

			.Title {
				padding: 0 var(--size-gap-quarter);
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				font-weight: bold;
				font-size: 1rem;
				line-height: 1;
				font-family: var(--font-highlight);
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.Date {
				padding: 0 var(--size-gap-quarter);
			}

			.Tickets {
				padding: 0 var(--size-gap-quarter);
				color: var(--color-brand-primary);
			}

			div,
			.Tickets {
				font-size: var(--font-small);

				> svg {
					padding-left: var(--size-gap-quarter);
				}
				&.SoldOut {
					color: var(--color-gray);
					text-decoration: line-through;
				}
			}
		}
	}
}
