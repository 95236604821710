@use '~/src/overtime-lib/src/www/lib/mixins';

.Index {
	display: flex;
	flex-direction: column;
	background-color: var(--color-lightgray);

	.Cards {
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		gap: var(--size-gap);
		background-color: var(--color-lightgray);
		padding: 16px;

		@include mixins.desktop {
			grid-template-columns: 1fr 1fr;
			justify-content: center;
			align-items: stretch;
			gap: var(--size-gap-2x);
			background-color: var(--color-lightgray);
			padding: var(--size-gap) 0;
		}

		.PlayoffStandings {
			.StandingsTable {
				grid-template-columns: auto repeat(2, 1fr);
			}
		}
	}
}
